<!-- 用户状态标签 -->
<template>
  <div>
    <crud ref="crud" :config="config"  @audit="audit" @auditAll="auditAll" @detailUserCar="detailSeed"></crud>
    <approve-modal ref='approveModal' @close='auditClose'/>

  </div>
</template>


<script>
import Crud from '@/components/crud/Crud'
import ApproveModal from './modules/ApproveModal.vue'
import { updateUserCar,approveAll } from '@/api/userCar/userCar'

export default {
  name: "index",
  components: {
    Crud,ApproveModal,
  },
  data() {
    return {
      config: {
        search: [
          { field: 'userName', name: '用户' },
          { field: 'areaName', name: '运营地点' },
        ],
        button: [
          { name: '一键审核',  event: 'auditAll', color: 'primary' }
        ],
        table: {
          api: '/user/user-operating-areas/page',
          deleteApi: '/user/user-operating-areas/delete',
          col: [
            { type: 'index' },
            { field: 'userName', name: '用户' },


            { field: 'areaName', name: '运营地点',   },

            { field: 'licensePlate', name: '车牌号', },
            { field: 'userImg',  name: '授权文件',type: "imgs", },
            { field: 'createTime', name: '创建时间',   },
            {
              type: 'button', button: [

                { name: '审核', event: 'audit' , checkShow: (rowData) => rowData.status == 0 },
                // { name: '删除', event: 'delete', checkShow: (row) => !(row.children && row.children.length) }
              ]
            },
          ]
        },
        // form: {
        //   addApi: `/user/user-cars/add`,
        //   editApi: `/user/user-cars/edit`,
        //   item: [
        //     { field: 'name', name: '名称', required: true },
        //     { field: 'pid', name: '上级', type: 'select', api: '/dynamic/dynamic-graph-category/selectList', reload: true },
        //     { field: 'sort', name: '序号', type: 'number', min: 0 },
        //   ]
        // }
      },
    }
  },
  methods: {
    audit(button, rowData) {
      this.$refs.approveModal.audit(rowData);
    },
    auditAll(){
      console.log("======一键审核====")
      let that = this;
      this.$confirm({
        title: '审核确认',
        content: '确认全部一键审核吗?',
        okText: '确认',
        cancelText: '取消',
        okType: 'danger',
        onOk: () => {
          approveAll( {}).then((res) => {
            if (res.success) {
              that.$message.success(res.message);
            } else {
              that.$message.warning(res.message)
            }
          }).finally(() => {
            this.$refs.crud.getList();
            that.confirmLoading = false

          })
        },
      });

    },
    auditClose() {
      this.$refs.crud.getList();
    },
    detailSeed(button, rowData){
      this.$refs.userCardInfo.initValue(rowData);
      this.$refs.userCardInfo.title = "详情";
      this.$refs.userCardInfo.visible = true;
    },
  }

}
</script>